<app-header [showSlider]="false" [navLight]="false"></app-header>
<br>
<br>
<br>
<br>
<div class="container-fluid py-6 px-5">
    <div class="text-center mx-auto mb-5" style="max-width: 600px;">
      <h1 class="display-5 mb-0">Frequently Asked Questions</h1>
      <hr class="w-25 mx-auto bg-primary">
      <p *ngIf="loading">Loading..</p>
    </div>
    <div class="accordion">
      <div class="accordion-item" *ngFor="let accord of accordion;let i = index;" id="accordionExample">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i"
            [attr.aria-controls]="'#collapse'+i">
            {{accord?.question}}
          </button>
        </h2>
        <div [id]="'collapse'+i" class="accordion-collapse collapse">
          <div class="accordion-body" [innerHTML]="accord?.answer | safe:'html'">
            <!-- {{accord?.answer}} -->
          </div>
        </div>
      </div>
    </div>
  </div>
<app-footer></app-footer>