import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from 'src/app/@service/seo.service';
import { tap } from 'rxjs/operators';
// import Swal from 'node_modules/sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private _seoService: SeoService,
    private _activatedRoute: ActivatedRoute
  ) {

  }

  routeChange$ = this._activatedRoute.queryParams.pipe(
    tap(data => {
      if (data['q']) {
        let el = document.getElementById(data['q']);
        el?.scrollIntoView();
      }
    })
  )

  ngOnInit(): void {
    this._seoService.setTitle("Taurus Education | New Age Automation in EdTech");
    this._seoService.updateMetaData({
      title: 'Taurus Education | New Age Automation in EdTech',
      description: `Best K12 School Software Serving Across India`,
      image: '/assets/images/main.png',
      keywords: 'School Software, Government Compliances,Remote Management'
    })
  }

}