import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poadcast-list',
  templateUrl: './poadcast-list.component.html',
  styleUrls: ['./poadcast-list.component.scss']
})
export class PoadcastListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  cards = [
    {
      iframe: "https://www.youtube.com/embed/blAw_6bZvxw?si=DOlmb3RBDRrIJTza",
      card_title: "1 conming soon...",
      card_text: "1 Coming Soon ! Stay Tuned !"
    },
    {
      iframe: "https://www.youtube.com/embed/lV3Xwvta-Zg?si=yKLcna9quBuAT36L",
      card_title: "2 conming soon...",
      card_text: "2 Coming Soon ! Stay Tuned !"
    },
    {
      iframe: "https://www.youtube.com/embed/buKxmeGnHK8?si=RdoZ6H42qOS-zUUe",
      card_title: "3 conming soon...",
      card_text: "3 Coming Soon ! Stay Tuned !"
    },
  ]
}
