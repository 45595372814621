<!-- Features Start -->
<div class="container-xxl py-5" id="feature">
    <div class="container py-5 px-lg-5">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">App Features</h5>
            <h1 class="mb-5">Awesome Features</h1>
        </div>
        <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="feature-item bg-light rounded p-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-gradient-1 rounded-circle mb-4"
                        style="width: 60px; height: 60px;">
                        <!-- <div class="d-inline-flex align-items-center justify-content-center mb-4 rounded-circle"
                        style="width: 60px; height: 60px;"> -->
                        <!-- <i class="fa fa-chart-user text-white fs-4"></i> -->
                        <img src="/assets/img/home-icon-removebg.png" style="width: 50px;" alt="Logo">

                    </div>
                    <h5 class="mb-3">Administration</h5>
                    <p class="m-0">Helps to manage classrooms and helps generate essential admin reports.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="feature-item bg-light rounded p-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-gradient-1 rounded-circle mb-4"
                        style="width: 60px; height: 60px;">
                        <!-- <i class="fa fa-clipboard-user text-white fs-4"></i> -->
                        <img src="/assets/img/attendance-removebg.png" style="width: 50px;" alt="Logo">
                    </div>
                    <h5 class="mb-3">Attendence</h5>
                    <p class="m-0">The attendence module provides a real-time attendance management system. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="feature-item bg-light rounded p-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-gradient-1 rounded-circle mb-4"
                        style="width: 60px; height: 60px;">
                        <!-- <i class="fa fa-edit text-white fs-4"></i> -->
                        <img src="/assets/img/fees-removebg.png" style="width: 50px;" alt="Logo">
                    </div>
                    <h5 class="mb-3">Fees</h5>
                    <p class="m-0">The fee management module provides an easy way to manage fees and track payments.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="feature-item bg-light rounded p-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-gradient-1 rounded-circle mb-4"
                        style="width: 60px; height: 60px;">
                        <!-- <i class="fa fa-shield-alt text-white fs-4"></i> -->
                        <img src="/assets/img/exam-removebg.png" style="width: 50px;" alt="Logo">
                    </div>
                    <h5 class="mb-3">Exam</h5>
                    <p class="m-0">Manage and schedule exam with ability to create final reports.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="feature-item bg-light rounded p-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-gradient-1 rounded-circle mb-4"
                        style="width: 60px; height: 60px;">
                        <!-- <i class="fa fa-cloud text-white fs-4"></i> -->
                        <img src="/assets/img/website.png" style="width: 50px;" alt="Logo">
                    </div>
                    <h5 class="mb-3">Website</h5>
                    <p class="m-0">Create your own school website and showcase your amazing school.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="feature-item bg-light rounded p-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-gradient-1 rounded-circle mb-4"
                        style="width: 60px; height: 60px;">
                        <!-- <i class="fa fa-mobile-alt text-white fs-4"></i> -->
                        <img src="/assets/img/communication-icons-removebg.png" style="width: 50px;" alt="Logo">
                    </div>
                    <h5 class="mb-3">Communication</h5>
                    <p class="m-0">Easily Communicate with all the stakeholders in education system. Send Bulk
                        messages.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Features End -->