<!-- About Start -->
<div class="container-xxl py-5" id="about">
    <div class="container py-5 px-lg-5">
        <div class="row g-5 align-items-center">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="text-primary-gradient fw-medium">About Taurus</h5>
                <h1 class="mb-4">#1 App For Your School</h1>
                <p class="mb-4">Imagine a world where student records are always secure and tamper-proof.<br> That's
                    the
                    world we're working towards!</p>
                <p class="mb-4">Our new platform for schools will help prevent data manipulation and ensure accurate
                    student accomplishments are recorded.</p>
                <div class="row g-4 mb-4">
                    <div class="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                        <div class="d-flex">
                            <!-- <i class="fa fa-cogs fa-2x text-primary-gradient flex-shrink-0 mt-1"></i> -->
                            <i class="fa fa-school fa-2x text-primary-gradient flex-shrink-0 mt-1"></i>
                            <div class="ms-3">
                                <h2 class="mb-0" data-toggle="counter-up">15+</h2>
                                <p class="text-primary-gradient mb-0">School</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                        <div class="d-flex">
                            <!-- <i class="fa fa-comments fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i> -->
                            <i class="fa fa-user-graduate fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i>
                            <div class="ms-3">
                                <h2 class="mb-0" data-toggle="counter-up">4000+</h2>
                                <p class="text-secondary-gradient mb-0">Students</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                        <div class="d-flex">
                            <!-- <i class="fa fa-comments fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i> -->
                            <i class="fa fa-chalkboard-teacher fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i>
                            <div class="ms-3">
                                <h2 class="mb-0" data-toggle="counter-up">150+</h2>
                                <p class="text-secondary-gradient mb-0">Teachers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="tel:+919767605767" target="_blank"
                    class="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3">Contact Us Today</a>
            </div>
            <div class="col-lg-6">
                <img class="img-fluid wow fadeInUp" data-wow-delay="0.5s" src="/assets/img/about.png">
            </div>
        </div>
    </div>
</div>
<!-- About End -->