<!-- Contact Start -->
<div class="container-xxl py-5" id="contact">
    <div class="container py-5 px-lg-5">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">Contact Us</h5>
            <h1 class="mb-5">Get In Touch!</h1>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="wow fadeInUp" data-wow-delay="0.3s">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <input type="hidden" name="key" value="0D789A874A">
                        <input type="hidden" name="value" value="EF01C0D7D2">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" name="user_full_name"
                                        placeholder="Your Name" #name="ngModel" required ngModel>
                                    <label for="name">Your Name</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="email" class="form-control" name="user_email_id"
                                        placeholder="Your Email" #email="ngModel" required ngModel>
                                    <label for="email">Your Email</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control" name="user_subject" placeholder="Subject"
                                        #subject="ngModel" required ngModel>
                                    <label for="subject">Subject</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="Leave a message here"
                                        name="user_message" #message="ngModel" required ngModel
                                        style="height: 150px"></textarea>
                                    <label for="message">Message</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <re-captcha name="recaptcha" required ngModel></re-captcha>
                            </div>
                            <div class="col-12 text-center">
                                <button class="btn btn-primary-gradient rounded-pill py-3 px-5" type="submit"
                                    [disabled]="contactForm.invalid || loading">
                                    {{loading ? 'Submiting...' : 'Send Message'}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact End -->