import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    private http: HttpClient
  ) { 
    this.getSheetData().subscribe(next => {
      this.loading = false;
      
    })
  }

  ngOnInit(): void {
  }

  accordion:any[] = [
  ]
 
  link = 'https://sheetdb.io/api/v1/apa64mb1pkbtg';
 
  loading = false;

  getSheetData() {
    this.loading = true;
    return this.http.get(this.link).pipe(
      map((data: any) => {
        this.accordion = data;
      })
    );
  }
}
