import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private renderer: Renderer2, private router: Router, private _activatedRoute: ActivatedRoute) {

  }

  @ViewChild('navBar', { static: true }) navBarElem!: ElementRef;


  ngOnInit(): void {
  }

  @Input()
  showSlider = true;

  @Input()
  navLight = true;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let scrollTop = document.documentElement.scrollTop;
    if (this.navLight) {
      if (scrollTop > 45) {
        this.renderer.addClass(this.navBarElem.nativeElement, 'sticky-top');
        this.renderer.addClass(this.navBarElem.nativeElement, 'shadow-sm');
      } else {
        this.renderer.removeClass(this.navBarElem.nativeElement, 'sticky-top');
        this.renderer.removeClass(this.navBarElem.nativeElement, 'shadow-sm');
      }
    }
  }

  selectedId = null;
  routeChange$ = this._activatedRoute.queryParams.pipe(
    tap(data => {
      if (data['q']) {
        this.selectedId = data['q'];
      }
    })
  )
  gotoSubRoute(id: any) {
    this.router.navigateByUrl(`/?q=${id}`)
  }

  routeAndScroll() {
    this.router.navigateByUrl(`/`);
    scrollTo({ top: 0, behavior: 'smooth' })
  }
}
