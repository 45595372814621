<!-- Process Start -->
<div class="container-xxl py-5">
    <div class="container py-5 px-lg-5">
        <div class="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">How It Works</h5>
            <h1 class="mb-5">3 Easy Steps</h1>
        </div>
        <div class="row gy-5 gx-4 justify-content-center">
            <div class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.1s">
                <div class="position-relative bg-light rounded pt-5 pb-4 px-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                        style="width: 100px; height: 100px;">
                        <i class="fa fa-cog fa-3x text-white"></i>
                    </div>
                    <h5 class="mt-4 mb-3">
                        Provide Your Current Classroom List and Student Count
                    </h5>
                    <p class="mb-0">
                        Give us your class list and student count. We'll provide an Excel sheet for student data.


                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.3s">
                <div class="position-relative bg-light rounded pt-5 pb-4 px-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                        style="width: 100px; height: 100px;">
                        <i class="fa fa-address-card fa-3x text-white"></i>
                    </div>
                    <h5 class="mt-4 mb-3">Import Your Excel File and Enjoy Flexibility</h5>
                    <p class="mb-0">
                        Import the Excel sheet and edit it on your desktop or mobile anytime.



                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.5s">
                <div class="position-relative bg-light rounded pt-5 pb-4 px-4">
                    <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                        style="width: 100px; height: 100px;">
                        <i class="fa fa-check fa-3x text-white"></i>
                    </div>
                    <h5 class="mt-4 mb-3">Unlock a World of Possibilities</h5>
                    <p class="mb-0">
                        Generate ID cards, mark attendance, manage fees, exams, and more in one place. Simplify your
                        educational tasks.





                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Process Start -->