<!-- Footer Start -->
<div class="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
    <div class="container py-5 px-lg-5">
        <div class="row g-5">
            <div class="col-md-6 col-lg-4">
                <h4 class="text-white mb-4">Address</h4>
                <p><i class="fa fa-map-marker-alt me-3"></i> Opp. to Alpha Petrol Pump, Kunjirwadi, Maharashtra
                    412201</p>
                <p>
                    <a class="text-white" href="tel:+919767605767" target="_blank"><i
                            class="fa fa-phone-alt me-3"></i>+91 9767605767</a>
                </p>
                <p><a class="text-white" href="mailto:oxeducation.research@gmail.com" target="_blank"><i
                            class="fa fa-envelope me-3"></i>oxeducation.research@gmail.com</a></p>
                <div class="d-flex pt-2">
                    <a class="btn btn-outline-light btn-social" href="https://twitter.com/taurus_edu/"><i
                            class="fab fa-twitter"></i></a>
                    <a class="btn btn-outline-light btn-social" href="https://www.youtube.com/@taurus-edu"><i
                            class="fab fa-youtube"></i></a>
                    <a class="btn btn-outline-light btn-social" href="https://www.instagram.com/oxvsys/"><i
                            class="fab fa-instagram"></i></a>
                    <a class="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/taurus-edu/"><i
                            class="fab fa-linkedin-in"></i></a>
                    <a class="btn btn-outline-light btn-social" href="https://wa.link/zmlw95"><i
                            class="fab fa-whatsapp"></i></a>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <h4 class="text-white mb-4">Quick Link</h4>
                <a class="btn btn-link" href="/assets/SampleReports.pdf">Reports</a>
                <a class="btn btn-link" href="https://www.youtube.com/watch?v=px-AM4Qu0ms">Watch Demo</a>
                <a class="btn btn-link" target="_blank" href="/assets/TaurusSchoolBanner.pdf">Brochure
                    <sub>pdf</sub></a>
                <a class="btn btn-link" target="_blank" href="/assets/TaurusSchoolBanner.png">Brochure
                    <sub>png</sub></a>
                <a class="btn btn-link" target="_blank" href="/assets/TaurusOnboardingFlow.pdf">Onboarding Flow
                    <sub>pdf</sub>
                </a>
                    
                <a class="btn btn-link"  routerLink="/faq">FAQ</a>
            </div>
            <div class="col-md-6 col-lg-3">
                <h4 class="text-white mb-4">Important Links</h4>
                <a class="btn btn-link" routerLink="/privacy-policy">Privacy Policy</a>
                <a class="btn btn-link" routerLink="/terms-and-conditions">Terms & Condition</a>
                <a class="btn btn-link" routerLink="/blog-list">Blogs</a>
                <a class="btn btn-link" routerLink="/poadcast-list">Poadcast</a>
                <a class="btn btn-link" routerLink="/our-team">Our Team</a>
            </div>
        </div>
    </div>
    <div class="container px-lg-5">
        <div class="copyright">
            <div class="row">
                <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">

                    <a class="border-bottom">
                        {{toady.getFullYear()}}
                        &copy;
                        Oxeducation Research And Resource Advancement Private
                        Limited
                    </a>
                </div>
                <a (click)="scrollTop()" class="btn btn-lg btn-lg-square back-to-top pt-2"><i
                        class="bi bi-arrow-up text-white"></i></a>
            </div>
        </div>
    </div>
</div>