import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tempP'
})
export class TempPPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let str = value.split('-')[1] as string;
    if (str) {
      return str.replace('.jpg', '');
    }
    return ''
  }

}
