<!-- Pricing Start -->
<ng-container *ngIf="ar$ | async as _"></ng-container>
<div class="container-xxl py-5" id="pricing">
    <div class="container py-5 px-lg-5">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">Pricing Plan</h5>
            <h1 class="mb-5">Choose Your Plan</h1>
        </div>
        <div class="tab-class text-center pricing wow fadeInUp" data-wow-delay="0.1s">
            <!-- <ul class="nav nav-pills d-inline-flex justify-content-center bg-primary-gradient rounded-pill mb-5">
                <li class="nav-item">
                    <button class="nav-link active" data-bs-toggle="pill" href="#tab-1">Monthly</button>
                </li>
                <li class="nav-item">
                    <button class="nav-link" data-bs-toggle="pill" href="#tab-2">Yearly</button>
                </li>
            </ul> -->
            <div class="tab-content text-start">
                <div id="tab-1" class="tab-pane fade show p-0 active">
                    <div class="row g-4">
                        <div class="col-lg-4">
                            <div class="bg-light rounded border">
                                <div class="border-bottom p-4 mb-4">
                                    <h4 class="text-primary-gradient mb-1">Basic</h4>
                                    <span>Powerful & Awesome Features</span>
                                </div>
                                <div class="p-4 pt-0">
                                    <div class="row">
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span  style="text-decoration: line-through; display: block; font-size: 0.8rem;height: 15px;">
                                                    <span *ngIf="discountPercent?.percent">
                                                        Not {{price1 | currency: 'INR'}}
                                                    </span>
                                                </span>
                                                <div>{{price1 * (discountPercent?.percent ? (1-(discountPercent?.percent)/100) : 1) | currency : 'INR' : 'symbol' : '1.0-0' }}</div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    per student
                                                </small>
                                            </h4>
                                        </div>
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span style="display: block;height: 15px;"></span>
                                                <div>{{slider1.value}} </div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    students
                                                </small>
                                            </h4>
                                        </div>
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span style="display: block;height: 15px;"></span>
                                                <div>{{(slider1.value ? slider1.value : 1 ) * (price1 * (discountPercent?.percent ? (1-(discountPercent?.percent)/100) : 1) ) | currency : 'INR'
                                                    : 'symbol' :
                                                    '1.0-0' }}
                                                </div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    yearly
                                                </small>
                                            </h4>
                                        </div>
                                    </div>

                                    <mat-slider [(ngModel)]="p1Value" #slider1 min="10" max="2000" step="10"
                                        showTickMarks="true" style=" width: 100%;" color="accent">
                                        <input matSliderThumb>
                                    </mat-slider>

                                    <ng-container *ngFor="let i of starterPricing">

                                        <div class="text-primary-gradient fw-medium mb-2 mt-3">{{i.title}}</div>
                                        <div *ngFor="let st of i.subtitle" class="d-flex justify-content-between mb-2">
                                            <span>{{st}}</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                        </div>

                                    </ng-container>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfc4NwzmDZXurbpoxuJyhv24wfCfVsZi8q6gmlu5eNvSzOo4w/viewform"
                                        target="_blank" class="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get
                                        Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="bg-light rounded border">
                                <div class="border-bottom p-4 mb-4">
                                    <h4 class="text-primary-gradient mb-1">Standard</h4>
                                    <span>Powerful & Awesome Features</span>
                                </div>
                                <div class="p-4 pt-0">
                                    <div class=" row">
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span  style="text-decoration: line-through; display: block; font-size: 0.8rem;height: 15px;">
                                                    <span *ngIf="discountPercent?.percent">
                                                        Not {{price2 | currency: 'INR'}}
                                                    </span>
                                                </span>
                                                <div>{{price2 * (discountPercent?.percent ? (1-(discountPercent?.percent)/100) : 1) | currency : 'INR' : 'symbol' : '1.0-0' }}</div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    per student
                                                </small>
                                            </h4>
                                        </div>
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span style="display: block;height: 15px;"></span>
                                                <div>{{slider2.value}} </div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    students
                                                </small>
                                            </h4>
                                        </div>
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span style="display: block;height: 15px;"></span>
                                                <div>{{(slider2.value ? slider2.value : 1 ) * (price2 * (discountPercent?.percent ? (1-(discountPercent?.percent)/100) : 1) ) | currency : 'INR'
                                                    : 'symbol' :
                                                    '1.0-0' }}
                                                </div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    yearly
                                                </small>
                                            </h4>
                                        </div>
                                    </div>
                                    <mat-slider [(ngModel)]="p2Value" #slider2 min="10" max="2000" step="10"
                                        showTickMarks="true" style=" width: 100%;" color="accent">
                                        <input matSliderThumb>
                                    </mat-slider>

                                    <ng-container *ngFor="let i of standardpricing">

                                        <div class="text-primary-gradient fw-medium mb-2 mt-3">{{i.title}}</div>
                                        <div *ngFor="let st of i.subtitle" class="d-flex justify-content-between mb-2">
                                            <span>{{st}}</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                        </div>

                                    </ng-container>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfc4NwzmDZXurbpoxuJyhv24wfCfVsZi8q6gmlu5eNvSzOo4w/viewform"
                                        target="_blank"
                                        class="btn btn-secondary-gradient rounded-pill py-2 px-4 mt-4">Get
                                        Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="bg-light rounded border">
                                <div class="border-bottom p-4 mb-4">
                                    <h4 class="text-primary-gradient mb-1">Enterprise</h4>
                                    <span>Powerful & Awesome Features</span>
                                </div>
                                <div class="p-4 pt-0">
                                    <div class=" row">
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span  style="text-decoration: line-through; display: block; font-size: 0.8rem;height: 15px;">
                                                    <span *ngIf="discountPercent?.percent">
                                                        Not {{price3 | currency: 'INR'}}
                                                    </span>
                                                </span>
                                                <div>{{price3 * (discountPercent?.percent ? (1-(discountPercent?.percent)/100) : 1) | currency : 'INR' : 'symbol' : '1.0-0' }}</div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    per student
                                                </small>
                                            </h4>
                                        </div>
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span style="display: block;height: 15px;"></span>
                                                <div>{{slider3.value}} </div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    students
                                                </small>
                                            </h4>
                                        </div>
                                        <div class="col-4">
                                            <h4 class="mb-3">
                                                <span style="display: block;height: 15px;"></span>
                                                <div>{{(slider3.value ? slider3.value : 1 ) * (price3 * (discountPercent?.percent ? (1-(discountPercent?.percent)/100) : 1) ) | currency : 'INR'
                                                    : 'symbol' :
                                                    '1.0-0' }}
                                                </div>
                                                <small class="align-bottom" style="font-size: 16px; line-height: 40px;">
                                                    yearly
                                                </small>
                                            </h4>
                                        </div>
                                    </div>
                                    <mat-slider [(ngModel)]="p3Value" #slider3 min="10" max="2000" step="10"
                                        showTickMarks="true" style=" width: 100%;" color="accent">
                                        <input matSliderThumb>
                                    </mat-slider>

                                    <ng-container *ngFor="let i of enterpricing">

                                        <div class="text-primary-gradient fw-medium mb-2 mt-3">{{i.title}}</div>
                                        <div *ngFor="let st of i.subtitle" class="d-flex justify-content-between mb-2">
                                            <span>{{st}}</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                        </div>

                                    </ng-container>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfc4NwzmDZXurbpoxuJyhv24wfCfVsZi8q6gmlu5eNvSzOo4w/viewform"
                                        target="_blank"
                                        class="btn btn-secondary-gradient rounded-pill py-2 px-4 mt-4">Get
                                        Started</a>
                                </div>
                            </div>
                        </div>



                        <!-- <div class="col-lg-4">
                            <div class="bg-light rounded">
                                <div class="border-bottom p-4 mb-4">
                                    <h4 class="text-primary-gradient mb-1">Enterprise</h4>
                                    <span>Powerful & Awesome Features</span>
                                </div>
                                <div class="p-4 pt-0">
                                    <div class="row">
                                        <div
                                            class="col-3 d-flex justify-content-center align-items-center text-center">
                                            <h4 class="mb-3">
                                                <div>{{100 | currency : 'INR' : 'symbol' : '1.0-0' }}</div>
                                                <small class="align-bottom"
                                                    style="font-size: 12px; line-height: 0px;">
                                                    per
                                                    <br>
                                                    student
                                                </small>
                                            </h4>
                                        </div>
                                        <div
                                            class="col-1 fw-bold text-secondary d-flex justify-content-center align-items-center">
                                            <span class="d-inline-block mb-4">X</span>
                                        </div>
                                        <div class="col-3 d-flex justify-content-center text-center">
                                            <h4 class="mb-3">
                                                <div>{{slider3.value}} </div>
                                                <small class="align-bottom"
                                                    style="font-size: 12px; line-height: 0px;">no.
                                                    students
                                                </small>
                                            </h4>
                                        </div>
                                        <div
                                            class="col-1 fw-bold text-secondary d-flex justify-content-center align-items-center">
                                            <span class="d-inline-block mb-4">=</span>
                                        </div>

                                        <div
                                            class="col-4 d-flex justify-content-center align-items-center text-center">
                                            <h4 class="mb-3">
                                                <div>{{(slider3.value ? slider3.value : 1 ) *100 | currency : 'INR'
                                                    : 'symbol' :
                                                    '1.0-0' }}
                                                </div>
                                                <small class="align-bottom"
                                                    style="font-size: 12px; line-height: 0px;">
                                                    yearly
                                                    <br>
                                                </small>
                                            </h4>
                                        </div>
                                    </div>
                                    <mat-slider [(ngModel)]="p3Value" #slider3 min="10" max="2000" step="10"
                                        showTickMarks="true" style=" width: 100%;" color="accent">
                                        <input matSliderThumb>
                                    </mat-slider>
                                    <ng-container *ngFor="let i of enterpricing">

                                        <div class="text-primary-gradient fw-medium mb-2 mt-3">{{i.title}}</div>
                                        <div *ngFor="let st of i.subtitle"
                                            class="d-flex justify-content-between mb-2"><span>{{st}}</span><i
                                                class="fa fa-check text-primary-gradient pt-1"></i></div>

                                    </ng-container>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfc4NwzmDZXurbpoxuJyhv24wfCfVsZi8q6gmlu5eNvSzOo4w/viewform"
                                        target="_blank"
                                        class="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get
                                        Started</a>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>
                <!-- <div id="tab-2" class="tab-pane fade p-0">
                    <div class="row g-4">
                        <div class="col-lg-4">
                            <div class="bg-light rounded">
                                <div class="border-bottom p-4 mb-4">
                                    <h4 class="text-primary-gradient mb-1">Starter Plan</h4>
                                    <span>Powerful & Awesome Features</span>
                                </div>
                                <div class="p-4 pt-0">
                                    <h1 class="mb-3">
                                        <small class="align-top"
                                            style="font-size: 22px; line-height: 45px;">$</small>114.99<small
                                            class="align-bottom" style="font-size: 16px; line-height: 40px;">/
                                            Yearly</small>
                                    </h1>
                                    <div class="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i
                                            class="fa fa-check text-primary-gradient pt-1"></i></div>
                                    <div class="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i
                                            class="fa fa-check text-primary-gradient pt-1"></i></div>
                                    <div class="d-flex justify-content-between mb-3"><span>Responsive
                                            Layout</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                    </div>
                                    <div class="d-flex justify-content-between mb-2"><span>Cross-browser
                                            Support</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                    </div>
                                    <a href="" class="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get
                                        Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="bg-light rounded border">
                                <div class="border-bottom p-4 mb-4">
                                    <h4 class="text-primary-gradient mb-1">Advance Plan</h4>
                                    <span>Powerful & Awesome Features</span>
                                </div>
                                <div class="p-4 pt-0">
                                    <h1 class="mb-3">
                                        <small class="align-top"
                                            style="font-size: 22px; line-height: 45px;">$</small>124.99<small
                                            class="align-bottom" style="font-size: 16px; line-height: 40px;">/
                                            Yearly</small>
                                    </h1>
                                    <div class="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i
                                            class="fa fa-check text-primary-gradient pt-1"></i></div>
                                    <div class="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i
                                            class="fa fa-check text-primary-gradient pt-1"></i></div>
                                    <div class="d-flex justify-content-between mb-3"><span>Responsive
                                            Layout</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                    </div>
                                    <div class="d-flex justify-content-between mb-2"><span>Cross-browser
                                            Support</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                    </div>
                                    <a href="" class="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get
                                        Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="bg-light rounded">
                                <div class="border-bottom p-4 mb-4">
                                    <h4 class="text-primary-gradient mb-1">Premium Plan</h4>
                                    <span>Powerful & Awesome Features</span>
                                </div>
                                <div class="p-4 pt-0">
                                    <h1 class="mb-3">
                                        <small class="align-top"
                                            style="font-size: 22px; line-height: 45px;">$</small>134.99<small
                                            class="align-bottom" style="font-size: 16px; line-height: 40px;">/
                                            Yearly</small>
                                    </h1>
                                    <div class="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i
                                            class="fa fa-check text-primary-gradient pt-1"></i></div>
                                    <div class="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i
                                            class="fa fa-check text-primary-gradient pt-1"></i></div>
                                    <div class="d-flex justify-content-between mb-3"><span>Responsive
                                            Layout</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                    </div>
                                    <div class="d-flex justify-content-between mb-2"><span>Cross-browser
                                            Support</span><i class="fa fa-check text-primary-gradient pt-1"></i>
                                    </div>
                                    <a href="" class="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get
                                        Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- Pricing End -->