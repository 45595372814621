<app-header [showSlider]="false" [navLight]="false"></app-header>
<br>
<br>
<br>
<h1 class="text-center"> Latest Blog By Taurus</h1>
<div class="container">
    <div class="row">
        <div class="col-12 col-md-3">
            <div class="card">
                <img src="https://mdbcdn.b-cdn.net/img/new/standard/nature/184.webp" class="card-img-top"
                    alt="Fissure in Sandstone" />
                <div class="card-body">
                    <h5 class="card-title">New Blog</h5>
                    <p class="card-text">
                        Coming Soon ! Stay Tuned !
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>