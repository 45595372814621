<!-- <div class="position-relative p-0"> -->


<!-- </div> -->
<div class="bg-white p-0">
    <!-- Spinner Start -->
    <!-- <div id="spinner"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div> -->
    <!-- Spinner End -->


    <!-- Navbar & Hero Start -->
    <app-header></app-header>
    <!-- Navbar & Hero End -->


    <!-- About Start -->
    <app-about-us></app-about-us>
    <!-- About End -->


    <!-- Features Start -->
    <app-app-list></app-app-list>
    <!-- Features End -->


    <!-- Screenshot Start -->
    <div class="container-xxl py-5">
        <div class="container py-5 px-lg-5">
            <div class="row g-5 align-items-center">
                <div class="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
                    <!-- <h5 class="text-primary-gradient fw-medium">Screenshot</h5> -->
                    <h1 class="mb-4">Why Choose Us ??</h1>

                    <p><i class="fa fa-check text-primary-gradient me-3"></i>No Vendor lock in</p>
                    <p><i class="fa fa-check text-primary-gradient me-3"></i>Easy to export data</p>
                    <p class="mb-4"><i class="fa fa-check text-primary-gradient me-3"></i>Web and Mobile Support</p>
                    <p class="mb-4">Research and Investment in Offline First Apps <br>Engagement with Industry experts
                        for many open-source Excel Processing Library</p>
                    <a href="tel:+919767605767" target="_blank"
                        class="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3">Contact Us Today</a>
                </div>
                <div class="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
                    data-wow-delay="0.3s">
                    <!-- <div class="owl-carousel screenshot-carousel"> -->
                    <img class="img-fluid" src="/assets/img/iphone-frame-2.png" alt="">
                    <!-- <img class="img-fluid" src="/assets/img/screenshot-2.png" alt="">
                        <img class="img-fluid" src="/assets/img/screenshot-3.png" alt="">
                        <img class="img-fluid" src="/assets/img/screenshot-4.png" alt="">
                        <img class="img-fluid" src="/assets/img/screenshot-5.png" alt=""> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Screenshot End -->


    <!-- Process Start -->
    <app-onboarding-steps></app-onboarding-steps>
    <!-- Process Start -->


    <!-- Download Start -->
    <!-- <div class="container-xxl py-5">
        <div class="container py-5 px-lg-5">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6">
                    <img class="img-fluid wow fadeInUp" data-wow-delay="0.1s" src="/assets/img/about.png">
                </div>
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                    <h5 class="text-primary-gradient fw-medium">Download</h5>
                    <h1 class="mb-4">Download The Latest Version Of Our App</h1>
                    <p class="mb-4">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita
                        erat ipsum et lorem et sit, sed stet no labore lorem sit clita duo justo eirmod magna dolore
                        erat amet</p>
                    <div class="row g-4">
                        <div class="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                            <a href="" class="d-flex bg-primary-gradient rounded py-3 px-4">
                                <i class="fab fa-apple fa-3x text-white flex-shrink-0"></i>
                                <div class="ms-3">
                                    <p class="text-white mb-0">Available On</p>
                                    <h5 class="text-white mb-0">App Store</h5>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                            <a href="" class="d-flex bg-secondary-gradient rounded py-3 px-4">
                                <i class="fab fa-android fa-3x text-white flex-shrink-0"></i>
                                <div class="ms-3">
                                    <p class="text-white mb-0">Available On</p>
                                    <h5 class="text-white mb-0">Play Store</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    Download End -->


    <!-- Pricing Start -->
    <app-pricing id="pricing"></app-pricing>
    <!-- Pricing End -->


    <!-- Testimonial Start -->
    <app-review id="review"></app-review>
    <!-- Testimonial End -->


    <!-- Contact Start -->
    <app-contact-us id="contact"></app-contact-us>
    <!-- Contact End -->

    <app-footer></app-footer>

    <ng-container *ngIf="routeChange$ | async as _"></ng-container>