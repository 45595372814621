<!-- Testimonial Start -->
<div class="container-xxl py-5" id="review">
    <div class="container py-5 px-lg-5">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">Testimonial</h5>
            <h1 class="mb-5">What Say Our Clients!</h1>
        </div>
        <!-- <div class="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s"> -->
        <div class="testimonial-item rounded p-4">
            <div class="d-flex align-items-center mb-4">
                <!-- <img class="img-fluid bg-white rounded flex-shrink-0 p-1" src="/assets/img/testimonial-1.jpg"
                    style="width: 85px; height: 85px;"> -->
                <div class="">
                    <h5 class="mb-1">Principal</h5>
                    <p class="mb-1">Muktai Memorial School</p>
                    <div>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                    </div>
                </div>
            </div>
            <p class="mb-0">
                The Taurus School Management System is an incredible software that has made my job much easier. It
                has helped me manage student records, generate reports, and manage finances with ease.
            </p>
        </div>
        <div class="testimonial-item rounded p-4">
            <div class="d-flex align-items-center mb-4">
                <!-- <img class="img-fluid bg-white rounded flex-shrink-0 p-1" src="/assets/img/testimonial-2.jpg"
                    style="width: 85px; height: 85px;"> -->
                <div class="">
                    <h5 class="mb-1">Sr. Clerk</h5>
                    <p class="mb-1">GSVVK</p>
                    <div>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                    </div>
                </div>
            </div>
            <p class="mb-0">The software is user-friendly and has significantly reduced the time and effort required
                to complete administrative tasks.</p>
        </div>

        <div class="testimonial-item rounded p-4">
            <div class="d-flex align-items-center mb-4">
                <!-- <img class="img-fluid bg-white rounded flex-shrink-0 p-1" src="/assets/img/testimonial-4.jpg"
                    style="width: 85px; height: 85px;"> -->
                <div class="">
                    <h5 class="mb-1">Asst. Teacher</h5>
                    <p class="mb-1">RP High School</p>
                    <div>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                        <small class="fa fa-star text-warning"></small>
                    </div>
                </div>
            </div>
            <p class="mb-0">
                The Taurus School Management System is a fantastic software that simplifies the work of teachers
            </p>
        </div>
        <!-- </div> -->
    </div>
</div>
<!-- Testimonial End -->