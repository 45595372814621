<app-header [showSlider]="false" [navLight]="false"></app-header>
<br>
<br>
<br>


<h1 class="text-center">Stay Stune For Some Intresting Poadcast!</h1>
<div class="container mt-5">
    <div class="row">
        <div class="col-4" *ngFor="let card of cards;let i = index;">
            <!-- <h1>Latest Videos</h1>
            <button class="btn btn-secondary-gradient mb-5">View More</button> -->
            <div class="card wow fadeInUp" [attr.data-wow-delay]="'0.'+i+2+'s'">
                <!-- <img src="https://mdbcdn.b-cdn.net/img/new/standard/nature/184.webp" class="card-img-top"
                    alt="Fissure in Sandstone" /> -->
                <iframe height="200" [src]="card.iframe | safe:'resourceUrl'" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
                <div class="card-body">
                    <h5 class="card-title">{{card.card_title}}</h5>
                    <p class="card-text">
                        {{card.card_text}}
                    </p>
                </div>
            </div>


        </div>
    </div>
</div>
<app-footer></app-footer>