<div class="position-relative p-0" id="home">
    <!-- 
    [ngClass]="{'navbar-expand-lg navbar-light':navLight, 'sticky-top shadow-sm':!navLight}" -->
    <nav #navBar class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0"
        [ngClass]="{ 'sticky-top shadow-sm':!navLight}">
        <a href="" class="navbar-brand p-0">
            <a href="/">
                <div class="logo"></div>
            </a>
            <!-- <img src="/assets/img/taurus-logo-text-dark.svg" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav mx-auto py-0">
                <a (click)="routeAndScroll()" class="nav-item nav-link"
                    ngClass="{'selectedId': selectedId==null}">Home</a>
                <!-- <a routerLink="about-us" class="nav-item nav-link active">Resources</a> -->
                <div class="nav-item dropdown">
                    <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Resources</a>
                    <div class="dropdown-menu rounded-0 rounded-bottom m-0">
                        <a routerLink="/faq" class="dropdown-item">Faq</a>
                        <a routerLink="/blog-list" class="dropdown-item">Blog</a>
                        <!-- <a href="appointment.html" class="dropdown-item">Appointment</a>
                        <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                        <a href="404.html" class="dropdown-item">404 Page</a> -->
                    </div>
                </div>
                <!-- <a routerLink="app-list" class="nav-item nav-link">Feature</a> -->
                <a (click)="gotoSubRoute('pricing')" [ngClass]="{'active': 'pricing'== selectedId}"
                    class="nav-item nav-link ">Pricing</a>
                <a (click)="gotoSubRoute('review')" [ngClass]="{'active': 'review'== selectedId}"
                    class="nav-item nav-link ">Review</a>
                <a (click)="gotoSubRoute('contact')" [ngClass]="{'active': 'contact'== selectedId}"
                    class="nav-item nav-link ">Contact</a>
            </div>
            <a href="https://wa.link/zmlw95" target="_blank"
                class="btn btn-warning rounded-pill py-2 px-4 ms-3 d-none d-lg-block">
                <span class="fw-semibold">
                    Contact Us
                </span>
                &nbsp;
                <i class="fs-5 text fab fa-whatsapp fa-1x"></i></a>
        </div>
    </nav>

    <div class="bg-primary hero-header" *ngIf="showSlider">
        <div class="container px-lg-5">
            <div class="row g-5" style="width: inherit;">
                <div class="col-lg-8 text-center text-lg-start">
                    <h1 class="text-white mb-4 animated slideInDown">The Revolutionary System That Helps Aided And
                        Non-Aided Schools.</h1>
                    <p class="text-white pb-3 animated slideInDown">Manage student records , generate reports and
                        manage fees with ease.</p>
                    <a href="https://calendly.com/siddhesh-kand/30min" target="_blank"
                        class="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">
                        Schedule a Demo
                    </a>
                    <!-- <a href=""
                        class="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill animated slideInRight">Contact
                        Us</a> -->
                </div>
                <div class="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
                    data-wow-delay="0.3s">
                    <!-- <div class="owl-carousel screenshot-carousel"> -->
                    <img class="img-fluid" src="/assets/img/iphone-frame.png" alt="">
                    <!-- <img class="img-fluid" src="/assets/img/screenshot-2.png" alt="">
                        <img class="img-fluid" src="/assets/img/screenshot-3.png" alt="">
                        <img class="img-fluid" src="/assets/img/screenshot-4.png" alt="">
                        <img class="img-fluid" src="/assets/img/screenshot-5.png" alt=""> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<ng-container *ngIf="routeChange$ | async as _"></ng-container>