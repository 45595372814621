import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/@service/seo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private _seoService: SeoService, private http: HttpClient) { }

  ngOnInit(): void {
  }
  loading = false;
  submit(contactForm: any) {
    this.loading = true;
    this.http.post(`https://backend2.oxvsys.com/api/v1/customer_mail/contact_form_submit`, {
      ...contactForm,
      key: "0D789A874A",
      value: "EF01C0D7D2"
    }).subscribe(next => {
      this.loading = false;
      Swal.fire('Thank you...', 'You submitted succesfully!', 'success')

    }, error => {

      this.loading = false;
      Swal.fire('Something went wrong', 'Please Contact us via email : oxeducation.research@gmail.com', 'error')
    })
  }
}
