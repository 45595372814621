import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './@components/header/header.component';
import { FooterComponent } from './@components/footer/footer.component';
import { HomeComponent } from './@components/home/home.component';
import { TncComponent } from './@components/tnc/tnc.component';
import { PrivacyPolicyComponent } from './@components/privacy-policy/privacy-policy.component';
import { BlogListComponent } from './@components/blog-list/blog-list.component';
import { PoadcastListComponent } from './@components/poadcast-list/poadcast-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { SafePipe } from './@pipes/safe.pipe';
import { AboutUsComponent } from './@components/about-us/about-us.component';
import { AppListComponent } from './@components/app-list/app-list.component';
import { OnboardingStepsComponent } from './@components/onboarding-steps/onboarding-steps.component';
import { PricingComponent } from './@components/pricing/pricing.component';
import { ReviewComponent } from './@components/review/review.component';
import { ContactUsComponent } from './@components/contact-us/contact-us.component';
import { OurTeamComponent } from './@components/our-team/our-team.component';
import { TempPPipe } from './@pipes/temp-p.pipe';
import { FaqComponent } from './@components/faq/faq.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    TncComponent,
    PrivacyPolicyComponent,
    BlogListComponent,
    PoadcastListComponent,
    SafePipe,
    AboutUsComponent,
    AppListComponent,
    OnboardingStepsComponent,
    PricingComponent,
    ReviewComponent,
    ContactUsComponent,
    OurTeamComponent,
    TempPPipe,
    FaqComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSliderModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: "6LeZd90ZAAAAAFqJYXE35InkzRXkE2QdQ6_bqhZY" } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
