import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  ngAfterViewInit(): void {
    const cells = document.querySelectorAll('.cell');
    cells.forEach(cell => {
      let img = cell.querySelector('img');
      if (!img) {
        return
      }
      let imagename = cell.attributes.getNamedItem('imagename')?.value;

      img.src = this.generateLink(imagename, 'O')
    })
  }
  generateLink(id: any, direction: any) {
    return `assets/img/reduce/${id}-${direction}.png`
  }
  teamData = [
    { id: "vinayak", imagename: "vinayak", name: 'Vinayak Gaikwad', designation: 'CEO(Fullstack Developer)' },
    { id: "akshay.s", imagename: "akshay.s", name: 'Akshay Shendkar', designation: 'COO(Frontend Lead)' },
    { id: "akshay.p", imagename: "akshay.p", name: 'Akshay Pawar', designation: 'CTO(Backend Lead)' },
    { id: "siddhesh", imagename: "siddhesh", name: 'Siddhesh Kand', designation: 'System Architecture' },
    { id: "niranjan", imagename: "niranjan", name: 'Niranjan Vitukade', designation: 'Frontend Developer' },
    { id: "sejal", imagename: "sejal", name: 'Sejal Bankar', designation: 'Frontend Developer' },
    { id: "snehal", imagename: "sneha", name: 'Sneha Kalbhor', designation: 'Fullstack Developer' },
    { id: "aniket", imagename: "aniket", name: 'Aniket Undre', designation: 'Jr.Frontend Developer' },
    { id: "sudarshan", imagename: "sudarshan", name: 'Sudarshan Kand', designation: 'UI Developer' },
    { id: "aryan", imagename: "aryan", name: 'Aryan Kunjir ', designation: 'UI Developer' },
    { id: "ruturaj", imagename: "ruturaj", name: 'Ruturaj Kunjir', designation: 'UI Developer' },
    { id: "rohan", imagename: "rohan", name: 'Rohan Kunjir', designation: 'UI Developer' },


  ]

  generateUp(cellNumber: number) {
    let up = [];
    let upTemp = cellNumber;
    while (upTemp > 0) {
      upTemp = upTemp - 4;
      if (upTemp > 0) {
        up.push(upTemp)
      }
    }
    return up
  }

  generateDown(cellNumber: number, maxSize: number) {
    let down = [];
    let downTemp = cellNumber;
    while (downTemp <= maxSize) {
      downTemp = downTemp + 4;
      if (downTemp <= maxSize) {
        down.push(downTemp)
      }
    }
    return down
  }
  generateLeft(cellNumber: number) {
    let left = [];
    let leftTemp = cellNumber;
    while (leftTemp % 4 != 1) {
      leftTemp = leftTemp - 1;
      left.push(leftTemp);
    }
    return left
  }
  generateRight(cellNumber: number, maxSize: number) {
    let right = [];
    let rightTemp = cellNumber;
    while (rightTemp % 4 != 0 && rightTemp < maxSize) {
      rightTemp = rightTemp + 1;
      right.push(rightTemp);
    }
    return right
  }

  getRelativePositions(cellNumber: any, maxSize: number) {
    cellNumber = parseInt(cellNumber);
    if (cellNumber > maxSize) {
      return {
        "u": [],
        "d": [],
        "l": [],
        "r": [],
        "ur": [],
        "dr": [],
        "ul": [],
        "dl": [],
      }
    }

    let up = this.generateUp(cellNumber);
    let down = this.generateDown(cellNumber, maxSize);
    let right = this.generateRight(cellNumber, maxSize);
    let left = this.generateLeft(cellNumber);

    let finUpRt: any = [];
    let finDownRt: any = [];
    right.forEach(temp => {
      let rup = this.generateUp(temp);
      finUpRt = [...finUpRt, ...rup];
      let rdown = this.generateDown(temp, maxSize);
      finDownRt = [...finDownRt, ...rdown];
    })

    let finUpLft: any = [];
    let finDownLft: any = [];
    left.forEach(temp => {
      let lup = this.generateUp(temp);
      finUpLft = [...finUpLft, ...lup];
      let ldown = this.generateDown(temp, maxSize);
      finDownLft = [...finDownLft, ...ldown];
    })

    return {
      "u": down,
      "d": up,
      "l": right,
      "r": left,
      "ur": finDownLft,
      "dr": finUpLft,
      "ul": finDownRt,
      "dl": finUpRt,
    }
    /*
    "ur": finUpRt,
      "dr": finDownRt,
      "ul": finUpLft,
      "dl": finDownLft,
    */
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    let cellPosition = 0;
    if (e.target.className.includes("cell")) {
      cellPosition = e.target.attributes.getNamedItem('cellposition')?.value;
    } else if (e.target.className.includes("team-img")) {
      cellPosition = e.target.parentElement.attributes.getNamedItem('cellposition')?.value;
    }
    if (cellPosition > 0) {
      let data = this.getRelativePositions(cellPosition, this.teamData.length) as any;
      for (let key in data) {
        data[key].forEach((dir: any) => {
          const cell = document.querySelector(`[cellposition="${dir}"]`);
          let imagename = cell?.attributes.getNamedItem('imagename')?.value;

          let img = cell?.querySelector('img');
          if (img) {
            img.src = this.generateLink(imagename, key.toUpperCase())
          }
        })
      }

      const cell = document.querySelector(`[cellposition="${cellPosition}"]`);
      let imagename = cell?.attributes.getNamedItem('imagename')?.value;

      let img = cell?.querySelector('img');
      if (img) {
        img.src = this.generateLink(imagename, 'O')
      }
    }
  }
}
