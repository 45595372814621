<app-header [showSlider]="false" [navLight]="false"></app-header>
<br>
<br>
<br>
<div class="container">
    <h4 class="mt-4 text-center fs-2">Our Team</h4>
</div>
<div class="container">
    <div class="row">
        <div class="col-2"></div>
        <div class="col-8">

            <div class="row">
                <div [id]="t.id" [attr.imagename]="t.imagename" [attr.cellposition]="i+1"
                    class="cell col-6 col-md-3 d-flex justify-content-center flex-column align-items-center"
                    *ngFor="let t of teamData;let i =index">
                    <!-- <span></span> -->
                    <img #imgRef class="arrow team-img" alt="">
                    <br>
                    <!-- <span>{{t.name}}:{{imgRef.src | tempP}}</span> -->
                    <div class="text-center fs-5 text-primary">{{t.name}}</div>
                    <div class="text-center fs-6">{{t.designation}}</div>
                </div>
            </div>

        </div>
        <div class="col-2"></div>
    </div>

</div>
<app-footer></app-footer>