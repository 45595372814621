import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor(
    private ar: ActivatedRoute
  ) { }

  discountOptions:any = {
    'mmd2023': {
      percent:15,
      title:'अभिनंदन आपणास १५% सूट उपलब्ध ५१ व्या राज्यव्यापी शिक्षकेतर महामंडळ अधिवेशनामध्ये नोंदणी करणार्‍या शाळांसाठी'
    }
  }

  discountPercent:any = null;

  ar$ = this.ar.queryParams.pipe(
    tap(qp => {
      if (qp['discount']) {
        let discountPer= this.discountOptions[qp['discount'] as string];
        if(discountPer){
          this.discountPercent = discountPer;
          Swal.fire('Thank you...', this.discountPercent.title, 'success')
        }
      }
    })
  )

  ngOnInit(): void {
  }

  p1Value = 50;
  p2Value = 50;
  p3Value = 50;

  price1 = 70;
  price2 = 90;
  price3 = 120;

  loading = false;



  title = 'frontend';

  starterPricing = [
    {
      title: "Administration",
      subtitle: [
        "Individual/Bulk (LC, Bonafied & ID Card)",
        "Import & Export students via excel",
        "Students migration from one class-room to another class-room",
      ]
    },
    {
      title: "Attendance",
      subtitle: [
        "Mark bulk attendance daily",
      ]
    },
    {
      title: "Fees",
      subtitle: [
        "Ability to create fees in bulk",
        "Plan fees",
        "Collect fees",
        "Analytics for fees",
      ]
    },
    {
      title: "Exam",
      subtitle: [
        "Plan Subjects",
        "Plan Exams",

      ]
    },
  ]
  standardpricing = [
    {
      title: "Home",
      subtitle: [
        "Individual/Bulk (LC, Bonafied & ID Card)",
        "Import & Export students via excel",
        "Students migration from one class-room to another class-room"
      ]
    },
    {
      title: "Attendance",
      subtitle: [
        "Mark bulk attendance daily",
      ]
    },
    {
      title: "Fees",
      subtitle: [
        "Ability to create fees in bulk",
        "Plan fees",
        "Collect fees",
        "Analytics for fees",
      ]
    },
    {
      title: "Exam",
      subtitle: [
        "Plan Subjects",
        "Plan Exams",

      ]
    },
    {
      title: "Parents",
      subtitle: [
        "Student Dashboard",
        "Notifications",

      ]
    },
  ]
  enterpricing = [
    {
      title: "Home",
      subtitle: [
        "Individual/Bulk (LC, Bonafied & ID Card)",
        "Import & Export students via excel",
        "Students migration from one class-room to another class-room"
      ]
    },
    {
      title: "Attendance",
      subtitle: [
        "Mark bulk attendance daily",
      ]
    },
    {
      title: "Fees",
      subtitle: [
        "Ability to create fees in bulk",
        "Plan fees",
        "Collect fees",
        "Analytics for fees",
      ]
    },
    {
      title: "Exam",
      subtitle: [
        "Plan Subjects",
        "Plan Exams",

      ]
    },
    {
      title: "Parents",
      subtitle: [
        "Student Dashboard",
        "Notifications",

      ]
    },
    {
      title: "Website",
      subtitle: [
        "Website with Admin Panel",
      ]
    },
  ]
}


