import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './@components/home/home.component';
import { PrivacyPolicyComponent } from './@components/privacy-policy/privacy-policy.component';
import { TncComponent } from './@components/tnc/tnc.component';
import { BlogListComponent } from './@components/blog-list/blog-list.component';
import { PoadcastListComponent } from './@components/poadcast-list/poadcast-list.component';
import { AboutUsComponent } from './@components/about-us/about-us.component';
import { AppListComponent } from './@components/app-list/app-list.component';
import { OnboardingStepsComponent } from './@components/onboarding-steps/onboarding-steps.component';
import { PricingComponent } from './@components/pricing/pricing.component';
import { ReviewComponent } from './@components/review/review.component';
import { ContactUsComponent } from './@components/contact-us/contact-us.component';
import { OurTeamComponent } from './@components/our-team/our-team.component';
import { FaqComponent } from './@components/faq/faq.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'terms-and-conditions', component: TncComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'blog-list', component: BlogListComponent },
  { path: 'poadcast-list', component: PoadcastListComponent },
  { path: 'our-team', component: OurTeamComponent },
  { path: 'faq', component: FaqComponent },
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
